<script>
import { companies } from "@/config/api/companies";

/**
 * Add Member Component
 */
export default {
  props: ["currentCompany", "selectedMember"],
  data() {
    return {
      title: "Members",
      items: [
        {
          text: "All Members",
          to: { path: `/members` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      userId: null,
      loading: false,
      values: {},
      schema: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Member name",
          placeholder: "John Doe",
          "validation-messages": { required: "Member Name is required" },
          validation: "required",
        },
        {
          index: 2,
          type: "text",
          name: "email",
          label: "Member email",
          "validation-messages": { required: "Member Email is required" },
          placeholder: "example@email.com",
          validation: "required|email",
          // errorBehavior: "live",
        },
        {
          index: 3,
          type: "text",
          name: "position",
          label: "Position",
          validation: "required",

        },
        {
          index: 4,
          type: "checkbox",
          name: "isAdmin",
          label: "Admin",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedMember) {
        this.updateMember(data);
      } else {
        this.addMember(data);
      }
    },
    addMember(data) {
      this.loading = true;

      const api = companies.addMember;
      api.data = {
        ...data,
        isAdmin: data.isAdmin ? data.isAdmin : false,
        company_id: this.currentCompany._id,
        user_id: data.user_id ? data.user_id : null,
      };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Member added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Member is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMember(data) {
      this.loading = true;
      const api = companies.updateMember;
      api.id = data._id;
      api.data = { ...data };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Member updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Member is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(async () => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {
    selectedMember(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Member"
    size="xl"
    @hidden="$emit('resetModal')"
    :title="selectedMember ? 'Update Member' : 'Add Member'"
    hide-footer
  >
    <FormulateForm
      name="MemberAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedMember"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedMember"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1650px !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 1200px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 950px !important;
  }
}
</style>