<script>
import { awards } from "@/config/api/awards";
import { getFormData } from "@/utils/createForm";
export default {
    data() {
        return {
            loading: false,
            values: {},
            award_document: '',
            currentAward: null,
            schema: [
                {
                    index: 1,
                    type: "text",
                    name: "name",
                    label: "Award Name",
                    "validation-messages": { required: "Award name is required" },
                    validation: "required",
                },
                {
                    index: 2,
                    type: "textarea",
                    name: "description",
                    label: "Description",
                },
                {
                    index: 3,
                    type: "date",
                    name: "date_received_on",

                    label: "Date received",
                    "validation-messages": { required: "Date received is required" },
                    validation: "required",
                },
            ],
        };
    },
    props: {
        companyId: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => {
                return []
            },
        },
        selectedAward: {
            type: Object,
        },
    },

    methods: {

        addAward(data) {
            this.loading = true;
            const api = awards.companies.adminCreate;
            api.data = data;
            // console.log("adding award")
            // console.log(api.data)
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Award added successfully!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });

                })
                .catch((err) => {
                    this.$bvToast.toast("Award is not added!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateAward(data) {
            this.loading = true;
            console.log("currentAward",this.currentAward)
            const api = {
                url: `admin/award/company/update/${this.currentAward._id}`,
                method: "POST",
                data: null,
            }
            api.data = data;
            this.generateMultipartAPI(api)
                .then(() => {
                    this.$bvToast.toast("Award updated successfully!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
 

                })
                .catch((err) => {
                    this.$bvToast.toast("Award is not updated!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        submitHandler(data) {
            if (this.currentAward) {

                const formdata = {
                    // ...data,
                    // is_verified: true,
                    name: data.name,
                    description: data.description,
                    company_id: this.companyId,
                    award_document: this.award_document.fileList[0],
                    date_received: data.date_received_on
                }
                this.updateAward(getFormData(formdata))
            } else {
                const formdata = getFormData({
                    ...data,
                    file: this.award_document.fileList[0],
                    is_verified: true,
                    company_id: this.companyId,
                    date_received: data.date_received_on

                })
                this.addAward(formdata)
            }

        },
        openModal() {
            this.$bvModal.show('company-award');
        },

    },
    watch: {
        selectedAward(val) {
            this.currentAward = val
            this.award_document = [
                {
                    url: val.award_document
                }
            ]
            this.values = {
                date_received_on: this.moment(val.date_received).format(
                    "YYYY-MM-DD"
                ),
                ...this.currentAward,
            };
        }
    }
};
</script>
    
<template>
    <div>
        <div class="text-right  ">
            <button class="trade-licence-btn" @click="openModal">
                Add Award
            </button>
        </div>

        <b-modal size="md" id="company-award" :title="currentAward ? 'Update Company Award' : 'Add Company Award'"
            @hide="$emit('resetModal')" hide-footer class="mb-5">

            <FormulateForm name="AwardAction" v-model="values" :schema="schema" @submit="submitHandler">
                <div class="row">
                    <div class="col-12">
                        <FormulateInput type="image" name="award_document" v-model="award_document"
                            label="Upload Award Document" help="Select a png or jpg to upload." :uploader="() => { }" />
                    </div>


                </div>
                <FormulateInput type="submit" class="float-right">
                    <div v-if="loading">
                        <b-spinner class="vueformulate-loader"></b-spinner>
                        <span v-if="currentAward"> Updating... </span>
                        <span v-else> Adding... </span>
                    </div>
                    <div v-else>
                        <span v-if="currentAward"> Update </span>
                        <span v-else> Add </span>
                    </div>
                </FormulateInput>
            </FormulateForm>

        </b-modal>
    </div>
</template>
<style scoped>
.trade-licence-btn {
    background: none;
    border: none;
}
</style>
    