export const company_categories = {
  create_main: {
    url: "category/main/create",
    method: "POST",
    data: null,
  },
  create_sub: {
    url: "category/sub/create",
    method: "POST",
    data: null,
  },
  update_main: {
    url: "category/main/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  update_sub: {
    url: "category/sub/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get_main: {
    url: "admin/category/main/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  get_sub: {
    url: "admin/category/sub/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  delete_main: {
    url: "category/main/delete/",
    method: "DELETE",
    id: null,
    params: null,
  },
  delete_sub: {
    url: "category/sub/delete/",
    method: "DELETE",
    id: null,
    params: null,
  },
  company_category_permission: {
    url: "company-category-permission",
    method: "GET",
    id: null,
    params: null,
  },
};
