<script>
import { companies } from "@/config/api/companies";
import { getFormData } from "@/utils/createForm";

export default {
  data() {
    return {
      formValues: {
        trade_license_expiry: this.moment(
          this.data.trade_license_expiry_date
        ).format("YYYY-MM-DD"),
        trade_license_docs: null,
      },
    };
  },
  props: ["data"],

  methods: {
    openTradeLicenseModal() {
      this.$bvModal.show(`trade-license-modal-${this.data._id}`);
    },
    submitNewData() {
      const api = companies.updateTradeLicense;
      api.data = getFormData({
        company_id: this.data._id,
        expiry_date: this.formValues.trade_license_expiry,
        file: this.formValues.trade_license_docs,
      });

      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Trade license is updated!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit('onTradeLicense', this.formValues.trade_license_expiry);
          this.$bvModal.hide(`trade-license-modal-${this.data._id}`);
        })
        .catch((err) => {
          this.$bvToast.toast("Trade license is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="text-right  ">
      <button class="trade-licence-btn" @click.stop="openTradeLicenseModal">
        {{data.trade_license_expiry_date? moment(data.trade_license_expiry_date ).format("LL") : "No date" }}

      </button>
    </div>

    <b-modal size="md" :id="`trade-license-modal-${data._id}`" title="Trade license" hide-footer class="mb-5">
      <div class="row">
        <div class="col-4">
          <div v-if="data.trade_license">
            <a :href="data.trade_license" target="_blank">
              <img :src="data.trade_license" style="width: 100%" alt="Trade license" />
            </a>
          </div>
          <div v-else>No Trade license</div>
        </div>
        <div class="col-8">
          <FormulateInput type="date" v-model="formValues.trade_license_expiry" label="Trade license
            expiry" />
          <b-form-group label="Trade license" class="mt-2">
            <b-form-file v-model="formValues.trade_license_docs" accept="*"
              placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
          </b-form-group>

          <FormulateInput @click="submitNewData" type="button" label="Save" />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style scoped>
.trade-licence-btn {
  background: none;
  border: none;
}
</style>
