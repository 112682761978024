<script>
import { cities } from "@/config/api/cities";
export default {
  props: ["selectedCity", "countries", "currentCountry"],
  mounted() {
    console.log(this.currentCountry);
  },
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          label: "Country",
          name: "country.name",
          value: this.currentCountry.name,
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
              console.log(this.values);
              console.log(data);

      if (this.selectedCity) {
        data.country = this.countries.find((i) => i.name === this.values["country.name"])._id;
        this.updateCity(data, this.selectedCity._id);
      } else {
        data.country_id = this.countries.find(
          (i) => i.name === this.values["country.name"]
        )._id;
        this.addCity(data);
      }
    },
    addCity(data) {
            delete data["country.name"];

      this.performAction = true;
      const api = cities.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("CityAction");
          this.$bvToast.toast("City added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("City is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCity(data, id) {
      this.performAction = true;
      delete data["country.name"];
      const api = cities.update;
      api.data = data;
      api.id = id;
      console.log(data);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("City updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("City is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedCity(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    :id="'action-City_' + currentCountry._id"
    @hidden="$emit('resetModal')"
    :title="selectedCity ? 'Update City' : 'Add City'"
    hide-footer
  >
    <FormulateForm
      name="CityAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCity"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCity"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>