<script>
import { companies } from "@/config/api/companies";
import { getFormData } from "@/utils/createForm";
export default {
    props: ["selectedCompany", "companyStatuses"],
    data() {
        return {
            values: {},
            image: null,
            performAction: false,
            schema: [

                {
                    index: 0,
                    type: "select",
                    name: "company_statuses",
                    label: "Company Status",
                    options: this.companyStatuses.map((val) => {
                        return {
                            value: val._id,
                            label: val.name,
                        };
                    }),
                },
            ],
        };
    },
    methods: {
        resetModal() {
            this.image = null;
            this.$formulate.reset("CompanyStatusAction", {});
            this.$emit("resetModal");
        },
        async submitHandler(data) {
            if (this.selectedCompany) {

                data = {
                    "status": data.company_statuses
                }
                this.updateCompanyStatus(data, this.selectedCompany._id);
            }
        },

        updateCompanyStatus(data, id) {
            this.performAction = true;
            const api = companies.updateStatus;
            api.data = data;
            api.id = id;
            console.log("hereree")
            console.log(api)
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Company Status updated successfully!", {
                        title: "Update",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                    this.$emit("closeModal");
                })
                .catch((err) => {
                    this.$bvToast.toast("Company Status is not updated!", {
                        title: "Update",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err.response.data);
                })
                .finally(() => {
                    this.performAction = false;
                });
        },
    },
    watch: {
        selectedCompany(val) {
            if (val) {
                val.company_statuses = val.status;
                this.values = { ...val };
            }
        },
        companyStatuses(val) {
            if (val) {
                this.schema.find((item) => item.index == 0).options = val.map((val) => {
                    return {
                        value: val._id,
                        label: val.name,
                    };
                });
            }
        },
    },
};
</script>

<template>
    <b-modal id="action-company-status" @hide="resetModal" :title="'Update Company Status' " hide-footer>
        <FormulateForm name="CompanyStatusAction" v-model="values" :schema="schema" @submit="submitHandler">

            <FormulateInput type="submit" class="float-right">
                <span v-if="performAction">
                    <b-spinner class="vueformulate-loader"></b-spinner>
                    <span v-if="selectedCompany"> Updating... </span>
                </span>
                <span v-else>
                    <span v-if="selectedCompany"> Update </span>
                </span>
            </FormulateInput>
        </FormulateForm>
    </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
    display: flex;
}

.payment-duration .formulate-input-group-item {
    margin: 8px;
}

.payment-duration .formulate-input-label {
    margin-top: -6px;
}
</style>