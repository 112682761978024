export const claims = {
  apply: {
    url: "admin/claim/apply",
    method: "POST",
    data: null,
  },
  decline: {
    url: "admin/claim/decline",
    method: "POST",
    data: null,
  },
  get: {
    url: "admin/claim/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  getUserProjectClaimV2: {
    url: "user/claim/getUserProjectClaimV2/",
    method: "GET",
    id: null,
    params: null,
  },
};
