<script>
import { getFormData } from "../../utils/createForm";
import { companies } from "../../config/api/companies";
export default {
  props: {
    company: {
      type: Object,
    },
    projects: {
      type: Array,
    },
    awards: {
      type: Array,
    },
  },
  data() {

    return {
      addCertificate: false,
      formValues: {
        certificate_document: null,
        certificate_image: null,
      },
    };
  },
  methods: {
    openRatingModal() {
      this.$bvModal.show("company-rating-modal");
    },
    submitForm() {
      const data = {
        company_id: this.$route.query.id,
        title: this.formValues.title,
        description: this.formValues.description,
        certificate_document:
          this.formValues.certificate_document.files[0].file,
        certificate_image: this.formValues.certificate_image.files[0].file,
      };
      const api = companies.createCertificate;
      api.data = getFormData(data);
      this.addCertificate = true;
      this.generateMultipartAPI(api)
        .then((res) => {
          this.$bvToast.toast("Certificate added successfully!", {
            title: "Add",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("company-rating-modal");
          this.$emit("added", res.data.companyCertificate);
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Certificate is not added, ${err.response.data.message}`,
            {
              title: "Add",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.addCertificate = false;
        });
    },
  },
};
</script>

<template>
  <div v-if="company && projects && awards">
    <div class="text-right mb-4">
      <b-button variant="primary" @click="openRatingModal"> Rating {{  company.rating  }} / 10 </b-button>
    </div>

    <b-modal size="sm" id="company-rating-modal" title="Rating Info" hide-footer>
      <div class="row text-center">
        <div class="col-12">
          <h4>Over View ({{  company.rating || 0  }}/10)</h4>
        </div>
        <div class="col-12 row">
          <div class="col-3">
            <h6>Content</h6>
          </div>
          <div class="col-3">
            <h6>Maximum Stars</h6>
          </div>
          <div class="col-3">
            <h6>Count</h6>
          </div>
          <div class="col-3">
            <h6>Company Stars</h6>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-3">Views</div>
          <div class="col-3">4</div>
          <div class="col-3">{{  company.views  }}</div>
          <div class="col-3">{{  company.views > 1000 ? 4 : company.views > 500 ? 3 : company.views > 200 ? 2 :
          company.views > 50 ? 1 : 0












            }}
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-3">Award</div>
          <div class="col-3">3</div>
          <div class="col-3">{{  awards.length  }}</div>
          <div class="col-3">{{  awards.length > 3 ? 3 : awards.length  }}</div>
        </div>
        <div class="col-12 row">
          <div class="col-3">Projects</div>
          <div class="col-3">3</div>
          <div class="col-3">{{  projects.length  }}</div>
          <div class="col-3">{{  projects.length > 21 ? 3 : projects.length > 10 ?
          2.5 : projects.length > 4 ? 2 : projects.length > 1 ? 1 : 0



            }}</div>
        </div>
        <div class="col-12 row">
          <div class="col-3">
            <h6>Total</h6>
          </div>
          <div class="col-3">
            <h6>10</h6>
          </div>
          <div class="col-3">
            <h6>-</h6>
          </div>
          <div class="col-3">
            <h6>{{  company.rating  }}</h6>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.margin-top-custom {
  margin-top: 30px !important;
}
</style>
