<script>
import { companies } from "@/config/api/companies";
import { cities } from "@/config/api/cities";
import { levels } from "@/config/api/levels";
import { users } from "@/config/api/users";

import { getFormData } from "@/utils/createForm";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import City from "@/views/pages/cities";
import { debounce } from "lodash";

/**
 * Add Company Component
 */
export default {
  components: { Multiselect },
  props: ["selectedCompany", "selectCategoryOptions"],
  mounted() {
    console.log(this.selectCategoryOptions);
  },
  data() {
    return {
      companyCategories: [],
      title: "Companies",
      operatingCities: [],
      items: [
        {
          text: "All Companies",
          to: { path: `/companies` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      isLoading: false,
      plan_list_loading: false,
      tradeLicense: null,
      emiratesId: null,
      emiratesIdBack: null,
      coverImage: null,
      levelList: [],
      selectedPlanLevel: null,
      isVerified: null,
      isFeatured: null,
      loading: false,
      values: {},
      cities: [],
      schema: [
        {
          component: "div",
          class: "row",
          children: [
            {
              index: 1,
              type: "text",
              name: "legal_name",
              label: "Company Legal Name",
              class: "col-sm-6",
              "validation-messages": { required: "Company Legal is required" },
              validation: "required",
            },
            {
              index: 2,
              type: "text",
              name: "display_name",
              label: "Company Display Name",
              class: "col-sm-6",
              "validation-messages": {
                required: "Company Display is required",
              },
              validation: "required",
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              index: 3,
              type: "email",
              name: "company_email",
              label: "Company Email",
              class: "col-sm-6",
              "validation-messages": { required: "Company Email is required" },
              validation: "required",
            },
            {
              index: 4,
              type: "password",
              name: "password",
              label: "Company Password",
              class: "col-sm-6",
              "validation-messages": {
                required: "Company Password is required",
              },
              // validation: "required",
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            // {
            //   index: 5,
            //   type: "date",
            //   name: "trade_license_expiry",
            //   label: "Trade license expiry",
            //   class: "col-sm-6",
            //   "validation-messages": {
            //     required: "Trade license expiry is required",
            //   },
            //   validation: "required",
            // },
            {
              type: "text",
              name: "url",
              label: "Company Website",
              class: "col-sm-12",

            },
          ]
        },
        {
          component: "div",
          class: "row col-12 m-0 p-0",
          children: [
            {
              component: "div",
              class: "row m-0 p-0 col-sm-6 ",
              children: [
                {
                  index: 4,
                  type: "text",
                  name: "company_country_code",
                  class: "col-sm-5",
                  label: "Country code",
                },
                {
                  index: 8,
                  type: "text",
                  name: "company_phone",
                  class: "col-sm-7",
                  label: "Company Phone No.",
                },
              ],
            },
            {
              component: "div",
              class: "row col-sm-6 margin-top-custom",
              children: [
                {
                  index: 6,
                  type: "checkbox",
                  name: "is_verified",
                  class: "col-6",
                  label: "Verified",
                },
                {
                  index: 7,
                  type: "checkbox",
                  name: "is_featured",
                  class: "col-6",
                  label: "Featured",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.tradeLicense = null;
      this.emiratesId = null;
      this.emiratesIdBack = null;
      this.coverImage = null;
      this.isVerified = null;
      this.isFeatured = null;
      this.values = {};
      this.companyCategories = [];

      this.operatingCities = [];
      this.$emit("resetModal");
    },
    searchForCity(text) {
      return debounce(this.loadAllCities(text), 1000);
    },
    loadAllCities(text) {
      if (text) {
        this.isLoading = true;
        const api = cities.searchCity;

        api.params = {
          search: text,
        };
        this.generateAPI(api)
          .then((res) => {
            this.cities = res.data.cities.map((item) => {
              return { name: item.name, id: item._id };
            });
          })
          .catch((err) => {
            console.log("Error", err.response.data);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    clearAll() {
      this.operatingCities = [];
    },

    async deleteDoc(type) {
      Swal.fire({
        title: "Are you sure, you want to delete this image?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",

        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.confirmDelete(type);
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("reloadData");
          this.$emit("closeModal");
          Swal.fire({
            title: "Image deleted successfully!",
          });
        }
      });
    },
    async confirmDelete(type) {
      const api = companies.removeDocuments;
      api.data = {
        company_id: this.selectedCompany._id,
        document_type: type,
      };
      await this.generateAPI(api)
        .then((res) => {
          this.$bvToast.toast("Image deleted successfully!", {
            title: "Delete",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          this.$bvToast.toast("Image is not deleted!", {
            title: "Delete",
            variant: "danger",
            solid: true,
          });
        });
      console.log(type);
    },
    async submitHandler(data) {
      console.log("data", data, this.selectedCompany)
      if (this.selectedCompany) {

        if (data.is_deleted) {
          data.deletedAt = data.is_deleted;
        }
        this.$delete(data, "coverImage");
        this.$delete(data, "emiratesId");
        this.$delete(data, "emiratesIdBack");
        this.$delete(data, "tradeLicense");

        data.operating_cities = undefined;
        data.company_experties = undefined;
        console.log("EMIORATES ABCCKFEKWOEF", this.emiratesIdBack.fileList[0])
        const formdata =
        {
          ...data,
          company_experties_array: this.companyCategories.map((item) => {
            return item.value;
          }).join(','),
          operating_cities_array: this.operatingCities.map((item) => item.id).join(','),
          cover_image: this.coverImage.fileList[0],
          trade_license: this.tradeLicense.fileList[0],
          emirates_id: this.emiratesId.fileList[0],
          emirates_id_back: this.emiratesIdBack.fileList[0],
          status: data.status[0] ? data.status[0]._id : null
        }
        console.log('formdata safasfewfew', formdata)
        this.updateCompany(formdata, this.selectedCompany._id);
      } else {
        this.$delete(data, "coverImage");
        this.$delete(data, "emiratesId");
        this.$delete(data, "emiratesIdBack");
        this.$delete(data, "tradeLicense");
        const pre_data = {
          ...data,
          company_experties: this.companyCategories.map((item) => {
            return item.value;
          }),
          operating_cities: this.operatingCities.map((item) => item.id),
          trade_license: this.tradeLicense && this.tradeLicense.fileList && this.tradeLicense.fileList[0],
          cover_image: this.coverImage && this.coverImage.fileList && this.coverImage.fileList[0],
          emirates_id: this.emiratesId && this.emiratesId.fileList && this.emiratesId.fileList[0],
          emirates_id_back: this.emiratesIdBack && this.emiratesIdBack.fileList && this.emiratesIdBack.fileList[0],

        };

        const formData = getFormData(pre_data);
        this.addCompany(formData);
      }
    },
    updateCompany(data, id) {
      this.loading = true;
      const api = companies.update;
      api.data = getFormData(data);
      api.id = id;
      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Company updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Company is not updated!,${err.response.data.message}`,
            {
              title: "Update",
              variant: "danger",
              solid: true,
            }
          );
          console.error(err.response);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });

    },
    addCompany(data) {
      this.loading = true;

      const api = companies.create;
      api.data = data;
      console.log(api)

      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Company added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Company is not added!,${err.response.data.message}`,
            {
              title: "Create",
              variant: "danger",
              solid: true,
            }
          );
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPlanLevels() {
      this.plan_list_loading = true
      const api = levels.getAll;

      api.params = {
        user_type: "company"
      };

      this.generateAPI(api).then((res) => {
        this.levelList = res.data.plans;
        this.plan_list_loading = false
      });
    },
    setPlanLevel() {
      this.loading = true;
      const api = users.setPlanLevel;
      api.data = {
        plan_level: this.selectedPlanLevel._id
      };
      api.id = this.selectedCompany.created_by._id;
      console.log(api)
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Company Plan level updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Company Plan level  is not updated!,${err.response.data.message}`,
            {
              title: "Update",
              variant: "danger",
              solid: true,
            }
          );
          console.error(err.response);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    }

  },
  watch: {
    selectedCompany(val) {
      if (val) {
        if (val.cover_image) {
          this.coverImage = [
            {
              url: val.cover_image,
            },
          ];
        }

        if (val.trade_license) {
          this.tradeLicense = [
            {
              url: val.trade_license,
            },
          ];

        }
        if (val.emirates_id) {
          this.emiratesId = [
            {
              url: val.emirates_id,
            },
          ];
        }
        if (val.emirates_id_back) {
          this.emiratesIdBack = [
            {
              url: val.emirates_id_back,
            },
          ];
        }

        if (val.created_by.plan_level) {
          this.selectedPlanLevel = val.created_by.plan_level
        }
        this.companyCategories = val.company_experties.map((item) => {
          return {
            value: item._id,
            name: item.name,
          };
        });

        this.operatingCities = val.operating_cities.map((item) => {
          return {
            id: item._id,
            name: item.name,
          };
        });

        this.values = {
          trade_license_expiry: this.moment(val.trade_license_expiry).format(
            "YYYY-MM-DD"
          ),
          ...val,
          password: "",
        };
        this.getPlanLevels()

      }
    },
  },
};
</script>

<template>
  <b-modal id="action-Company" size="xl" @hidden="resetModal"
    :title="selectedCompany ? 'Update Company' : 'Add Company'" hide-footer>
    <FormulateForm name="CompanyAction" v-model="values" :schema="schema" @submit="submitHandler">
      <div class="row-md-4" v-if="selectedCompany">
        <div class="row-3">
          <label for="input-5">Plan Level</label>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-6">
            <b-form-select v-model="selectedPlanLevel" class="mt-2">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  <span v-if="plan_list_loading">Loading Please wait </span>
                  <span v-else>-- Select Plan Level --</span>

                </b-form-select-option>

              </template>
              <b-form-select-option v-for="level in levelList" :key=level.id :value="level">
                {{ level.name }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-4 col-sm-6">
            <b-button type="button" @click="setPlanLevel()" pill variant="primary">
              Set Plan
            </b-button>
          </div>
        </div>

      </div>
      <div class="row my-2">
        <div class="col-12">
          <label for="ajax">Activity Area (Select City)</label>

          <multiselect v-model="operatingCities" id="ajax" label="name" track-by="id" placeholder="Type to search"
            open-direction="bottom" :options="cities" :multiple="true" :searchable="true" :loading="isLoading"
            :internal-search="false" :clear-on-select="false" :close-on-select="false" :blockKeys="['Delete']"
            :options-limit="300" :limit="10" :max-height="600" :show-no-results="false" @search-change="searchForCity">
            <!-- <template slot="clear" slot-scope="props">
              <div class="multiselect__clear" v-if="cities.length" @mousedown.prevent.stop="clearAll(props.search)">
              </div>
            </template><span slot="noResult">Oops! No elements found. Consider changing the search
              query.</span> -->
          </multiselect>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <label for=""> Company Expertise </label>
          <multiselect :multiple="true" label="name" v-model="companyCategories" :max="40" track-by="value"
            :blockKeys="['Delete']" :maxHeight="400" :searchable="true" placeholder="Please choose company expertise"
            :options="
              selectCategoryOptions.map((item) => {
                return {
                  name: item.label,
                  value: item.value,
                };
              })
            "></multiselect>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-6">
          <FormulateInput type="image" name="coverImage" v-model="coverImage" label="Cover image"
            help="Upload a cover image for the company (png/jpg)." />

          <button v-if="coverImage" type="button" @click="deleteDoc('cover_image')" class="delete-company-image">
            Delete
          </button>
        </div>

        <div class="col-md-6 col-sm-6">
          <FormulateInput type="image" name="tradeLicense" v-model="tradeLicense" label="Trade License"
            help="Upload company Trade License (png/jpg)." />
          <button v-if="tradeLicense" type="button" @click="deleteDoc('trade_license')" class="delete-company-image">
            Delete
          </button>
        </div>
      </div>
      <div class="row">

        <div class="col-md-6 col-sm-6">
          <FormulateInput type="image" name="emiratesId" v-model="emiratesId" label="Emirates ID (Front)"
            help="Upload Emirates ID (Front) (png/jpg)." />
          <button v-if="emiratesId" type="button" @click="deleteDoc('emirates_id')" class="delete-company-image">
            Delete
          </button>
        </div>
        <div class="col-md-6 col-sm-6">
          <FormulateInput type="image" name="emiratesIdBack" v-model="emiratesIdBack" label="Emirates ID (Back)"
            help="Upload company Emirates ID (Back) (png/jpg)." />
          <!-- <button v-if="emiratesIdBack" type="button" @click="deleteDoc('emirates_id_back')"
            class="delete-company-image">
            Delete
          </button> -->
        </div>
      </div>

      <div class="text-right my-4">
        <FormulateInput type="submit" class="float-right">
          <span v-if="loading">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedCompany"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedCompany"> Update </span>
            <span v-else> Add </span>
          </span>
        </FormulateInput>
      </div>
    </FormulateForm>
  </b-modal>
</template>

<style>
.margin-top-custom {
  margin-top: 30px !important;
}

.delete-company-image {
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  background: #fff;
  color: rgb(242 66 54);
  border: 1px solid rgb(242 66 54);
  border-radius: 4px;
}
</style>

