<script>
import { getFormData } from "../../utils/createForm";
import { companies } from "../../config/api/companies";
export default {
  data() {
    return {
      loading: false,
      certificate_document: '',
      formValues: {
        certificate_document: null,
        // certificate_image: null,
      },
    };
  },
  props: {
    selectedCertificate: {
      type: Object,
    },
  },
  methods: {
    openCertificate() {
      this.$bvModal.show("action-company-certificate");
    },

    submitHandler(data) {
      if (this.selectedCertificate) {

        const data = {
          company_id: this.$route.query.id,
          title: this.formValues.title,
          description: this.formValues.description,
          certificate_document:
            this.formValues.certificate_document.files[0].file,
          // certificate_image: this.formValues.certificate_image.files[0].file,
        };
        this.updateCertificate(data)
      } else {
        console.log('here')
        const data = {
          company_id: this.$route.query.id,
          title: this.formValues.title,
          description: this.formValues.description,
          certificate_document:
            this.certificate_document.files[0].file,
          // certificate_image: this.formValues.certificate_image.files[0].file,
        };
        this.addCertificate(data)
      }

    },

    addCertificate(data) {

      const api = companies.createCertificate;
      api.data = getFormData(data);
      this.loading = true;
      this.generateMultipartAPI(api)
        .then((res) => {
          this.$bvToast.toast("Certificate added successfully!", {
            title: "Add",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("action-company-certificate");
          this.$emit("added", res.data.companyCertificate);
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Certificate is not added, ${err.response.data.message}`,
            {
              title: "Add",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCertificate(data) {

      const api = companies.updateCertificate;
      api.data = getFormData(data);
      api.id = this.selectedCertificate._id;
      this.loading = true;
      this.generateMultipartAPI(api)
        .then((res) => {
          this.$bvToast.toast("Certificate updated successfully!", {
            title: "Add",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("action-company-certificate");
          this.$emit('resetModal')
          // this.$emit("added", res.data.companyCertificate);
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Certificate is not updated, ${err.response.data.message}`,
            {
              title: "Add",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    selectedCertificate(val) {

      // this.award_document = [
      //   {
      //     url: val.award_document
      //   }
      // ]
      this.formValues = {

        ...val,
        certificate_document: [
          { url: val.certificate_document }
        ]
      };
    }
  }
};
</script>

<template>
  <div>
    <div class="text-right mb-4">
      <b-button variant="primary" @click="openCertificate"> Add Certificate </b-button>
    </div>

    <b-modal size="sm" id="action-company-certificate"
      :title="selectedCertificate ? 'Update Certificate' : 'Add Certificate'" hide-footer>
      <FormulateForm v-model="formValues" @submit="submitHandler">
        <div class="row">
          <div class="col-md-6">
            <FormulateInput name="title" type="text" label="Title" placeholder="Title here" validation="required" />
          </div>
          <div class="col-md-6">
            <FormulateInput name="description" type="textarea" label="Description" placeholder="Description here"
              validation="required" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <FormulateInput type="file" name="certificate_document" v-model="formValues.certificate_document"
              label="Select the certificate documents" :uploader="() => {}" help="PDFs or Images"
              validation="required|mime:application/pdf,image/jpeg,image/png,image/gif" />
          </div>
        </div>

        <div class="text-right mt-4 mb-3">
          <FormulateInput type="submit" :disabled="loading">
            <div v-if="loading">
              <b-spinner class="vueformulate-loader"></b-spinner>
              <span v-if="selectedCertificate"> Updating... </span>
              <span v-else> Adding... </span>
            </div>
            <div v-else>
              <span v-if="selectedCertificate"> Update </span>
              <span v-else> Add </span>
            </div>
          </FormulateInput>
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<style>
.margin-top-custom {
  margin-top: 30px !important;
}
</style>
