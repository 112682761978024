<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { companies } from "@/config/api/companies";
import { company_categories } from "@/config/api/company_categories";
import { claims } from "@/config/api/claims";
import { awards } from "@/config/api/awards";
import CompanyModal from "@/components/companies/company-modal";
import CompanyStatusModal from "@/components/companies/edit-company-status";
import MemberModal from "@/components/companies/members/member-modal";
import companyCertificateModal from "@/components/companies/company-certificate-modal";
import CompanyRatingModal from "@/components/companies/company-rating-modal";
import TradeLicenseModal from "@/components/companies/trade-license-modal";
import CompanyAwardAdd from "@/views/pages/awards/company-awards";
import { company_status } from "../../../config/api/company-status";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    CompanyModal,
    MemberModal,
    companyCertificateModal,
    CompanyStatusModal,
    CompanyRatingModal, TradeLicenseModal, CompanyAwardAdd,
  },
  data() {
    return {
      selectCategoryOptions: [],
      title: "Company Info",
      companyInfo: null,
      selectedCompany: null,
      selectedAward: null,
      selectedCertificate: null,
      selectedMember: null,
      selectedClaim: null,
      companyStatus: null,
      companyProjects: [],
      companyAwards: [],
      companyClaims: [],
      companyStatuses: [],
      userList: [],
      loading: false,
      tabIndex: 0,
      items: [
        {
          text: "Companies",
          to: { name: "Companies" },
        },
        {
          text: "Company Info",
          active: true,
        },
      ],
    };
  },
  async created() {

    if (this.$route.query.id) {
      await this.loadInfo();
      this.loading = false;
    }
  },
  computed: {

  },
  methods: {
    openProjectPage(projectID) {
      this.$router.push({
        path: "/project-info",
        query: { id: projectID },
      });
    },
    showActionModal() {
      this.$bvModal.show("action-Company");
    },
    getCompanyProjects() {
      const api = companies.companyProject;
      api.params = {
        "companies.company": this.companyInfo._id,
      };
      this.generateAPI(api)
        .then((res) => {
          this.companyProjects = res.data.projects;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    getCompanyClaims() {
      const api = companies.claims;
      api.params = {
        "team_members.worked_under": this.companyInfo._id,
      };

      this.generateAPI(api)
        .then((res) => {
          this.companyClaims = res.data.claims.docs;
          console.log(this.companyClaims)
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    getCompanyAwards() {
      const api = companies.companyAwards;
      api.id = this.companyInfo._id;
      this.generateAPI(api)
        .then((res) => {
          this.companyAwards = res.data.models;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    removePic() {
      const api = companies.removeDocuments;
      api.data = {
        company_id: this.companyInfo._id,
        document_type: "cover_image",
      };
      this.generateAPI(api)
        .then(() => {
          this.companyInfo.cover_image = undefined;
          this.$bvToast.toast("Image deleted successfully!", {
            title: "Delete",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          this.$bvToast.toast("Image is not deleted!", {
            title: "Delete",
            variant: "danger",
            solid: true,
          });
        });
    },
    certificateAdded(data) {
      this.companyInfo.company_certificates.push(data);
    },
    toggleDetails(val) {
      val.toggleDetails();
      this.currentClaim(val);
    },
    currentClaim(data) {
      return (this.selectedClaim = data);
    },
    editClaim(data) {
      this.selectedClaim = data;
      let api = claims.apply;
      api.data = { id: data._id };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Claim is approved", {
            title: "Approve",
            variant: "success",
            solid: true,
          });
          this.loadInfo();
          this.tabIndex = 6;
        })
        .catch(() => {
          this.$bvToast.toast("Claim is not approved", {
            title: "Approve",
            variant: "danger",
            solid: true,
          });
        });

    },
    deleteClaim(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to decline this claim.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {

            const api = claims.decline;
            api.data = { id: data._id };
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Claim declined successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadInfo();
                this.tabIndex = 6;

              })
              .catch((err) => {
                this.$bvToast.toast("Claim is not declined", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteAward(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete this award`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {

            const api = awards.companies.delete;
            api.url += data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Award deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadInfo();
                this.tabIndex = 3;

              })
              .catch((err) => {
                this.$bvToast.toast("Award is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    deleteCertificate(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete this certificate`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {

            const api = companies.deleteCertificate
            api.url += data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Certificate deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadInfo();
                this.tabIndex = 2;

              })
              .catch((err) => {
                this.$bvToast.toast("Certificate is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeCompanyStatus(data) {
      const api = this.companyStatus ? companies.undelete : companies.delete;
      api.id = data._id;
      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        if (resdata.status == 200) {
          this.$bvToast.toast("Updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
        }

      }).finally(() => {
        this.loading = false;

      });
    },
    async loadInfo() {
      try {
        this.selectCategoryOptions = [];
        const api = company_categories.get_sub;
        this.generateAPI(api).then((res) => {
          res.data.categories.forEach((element) => {
            this.selectCategoryOptions.push({
              label: element.name
                ? element.name
                : `no name - id(${element._id})`,
              value: element._id,
            });
          });
        });
      } catch (error) {
        console.error(error);
      }
      if (this.$route.query.id) {
        const api = companies.getById;
        api.id = this.$route.query.id;
        this.loading = true;
        try {
          var res = await this.generateAPI(api);
          this.companyInfo = res.data.company;
          this.companyStatus = res.data.company.deletedAt == null;

        } catch (error) {
          console.error(error.response.data);
          this.$bvToast.toast("Could not get company details!", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          let currentUser = JSON.parse(localStorage.getItem("user"));
          console.log(currentUser);

          setTimeout(() => {
            this.$router.push({
              path:
                currentUser.user.user_type.name == "User"
                  ? "my-company"
                  : "companies",
            });
          }, 3000);
        }
        if (this.companyInfo && this.companyInfo._id) {
          console.log(this.companyInfo._id)
          this.getCompanyProjects(); this.getCompanyAwards(); this.getCompanyClaims(); this.loadCompanyStatus();
        }
      }
      this.loading = false;
    },
    showCompanyLink() {
      this.$bvModal.show("company-project-link");
    },
    hideCompanyLink() {
      this.$bvModal.hide("company-project-link");
    },
    showMemberView(val) {
      this.selectedMember = val;
      this.$router.push({
        path: "companies/member-info",
        query: { cid: val.company_id, mid: val._id },
      });
    },
    showMemberActionModal(data) {
      this.selectedCompany = data;
      this.$bvModal.show("action-Member");
    },
    showAddAward(data) {
      this.selectedAward = data;
      this.$bvModal.show("company-award");
    },
    showAddCertificate(data) {
      this.selectedCertificate = data;
      this.$bvModal.show("action-company-certificate");
    },
    showEditCompanyStatus(data) {
      this.selectedCompany = data;
      this.$bvModal.show("action-company-status");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Company");
      this.$bvModal.hide("action-Member");
      this.$bvModal.hide("company-award");
      this.$bvModal.hide("action-company-status");
    },
    editCompany(data) {
      this.selectedCompany = data;
      this.showActionModal();
    },
    deleteCompany(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this company?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            const api = companies.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Company deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                this.$bvToast.toast("Company is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
                this.loadInfo();
              })
              .finally(async () => {
                await this.$router.push("/companies");
                this.loading = false;
              });
          }
        });
    },

    loadCompanyStatus() {
      const api = company_status.getAll;
      this.generateAPI(api)
        .then((res) => {
          this.companyStatuses = res.data.company_statuses;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">


            <!-- end row -->

            <div class="">

              <div class="product-desc">
                <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4" v-model="tabIndex">
                  <b-tab title="General">
                    <div style="text-align: end; float-right" class="mb-2 mb-2">
                      <b-button class="mr-1" type="button" @click="deleteCompany(companyInfo)" pill variant="danger">
                        Delete
                      </b-button>
                      <b-button @click="editCompany(companyInfo)" pill variant="primary">
                        Edit
                      </b-button>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="mt-4 mt-xl-3">
                          <!-- <a v-if="companyInfo.company_experties" href="#" class="text-primary">{{
                                                   companyInfo.company_experties.name 
                                                  }}</a> -->
                          <div class="row">
                            <div class="col-8">
                              <h5 class="mt-1 mb-3">Legal Name:{{ companyInfo.legal_name }}</h5>
                              <h5 class="mt-1 mb-3">Display Name:{{ companyInfo.display_name }}</h5>
                            </div>
                            <div class="col-4">
                              <CompanyRatingModal :awards="companyAwards" :projects="companyProjects"
                                :company="companyInfo">
                              </CompanyRatingModal>

                            </div>
                          </div>

                          <div class="row col-12">
                            Trade License:
                            <TradeLicenseModal :data="companyInfo"
                              @onTradeLicense="(date) => { this.companyInfo.trade_license_expiry_date = date }" />
                            <span class="badge badge-danger float-right align-self-center"
                              v-if="companyInfo.trade_license_expiry_date && moment(companyInfo.trade_license_expiry_date).isBefore(moment())">Expired</span>
                          </div>
                          <div class="row col-12">
                            Website : &nbsp;
                            <a :href="companyInfo.url" target="_blank" v-if="companyInfo.url">
                              {{ companyInfo.url }}
                            </a>
                          </div>

                          <hr />
                          <div class="row">
                            <div class="col-md-12">
                              <h5 class="font-size-14">Basic Info :</h5>
                              <ul class="list-unstyled product-desc-list">
                                <li>
                                  <div class="row">
                                    <div class="align-center" style="margin-left:10px; margin-right:4px;">
                                      Company Status:
                                      {{ companyInfo.status[0] ? companyInfo.status[0].name : "Active" }}

                                    </div>
                                    <b-button class="align-center" type="button"
                                      @click="showEditCompanyStatus(companyInfo)" pill variant="primary">
                                      Edit Status
                                    </b-button>
                                  </div>
                                </li>
                                <li v-if="companyInfo.is_verified" class="verified_icon">
                                  Verified:
                                  <i style="color: green; font-size: 1.2rem" class="ri-checkbox-circle-fill"></i>
                                </li>
                                <li v-if="!companyInfo.is_verified" class="verified_icon">
                                  Verified:
                                  <i class="ri-close-circle-line verified_icon"
                                    style="color: red; font-size: 1.2rem"></i>
                                </li>
                                <li>
                                  Featured: {{ companyInfo.is_featured ? "Yes" : "No" }}
                                </li>


                                <li v-if="companyInfo.city">
                                  City: {{ companyInfo.city.name }}
                                </li>
                                <li v-if="companyInfo.country">
                                  Country: {{ companyInfo.country.name }}
                                </li>
                                <li>
                                  No. Members:
                                  {{ companyInfo.company_members.length }}
                                </li>
                                <li>
                                  Company Email:
                                  {{ companyInfo.company_email }}
                                </li>
                                <li>
                                  Company Contact: +{{
                                  companyInfo.company_country_code
                                  }}-{{ companyInfo.company_phone }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 border-right">
                            <h6>Activity Area</h6>

                            <div class="expertise-container">
                              <a v-for="city, index in companyInfo.operating_cities" :key="index"
                                style="cursor: pointer">
                                <div class="expertise-card">
                                  <strong> {{ city.name }}</strong>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div class="pl-2 col-md-6 ">
                            <h6>Experties</h6>
                            <div class="expertise-container ">
                              <a v-for="experties in companyInfo.company_experties" :key="experties._id"
                                style="cursor: pointer">
                                <div class="expertise-card" v-if="experties">
                                  <strong> {{ experties.name }}</strong>
                                  <div>{{ experties.main_category && experties.main_category.name }}
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="companyInfo.company_experties.length === 0">
                          No Experties
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="product-detail" v-if="companyInfo">
                          <div class="row mt-4">
                            <div class="col-md-11 col-9">
                              <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                                  <div class="product-img">
                                    <img v-if="companyInfo.cover_image" id="expandedImg" :src="companyInfo.cover_image"
                                      alt class="img-fluid mx-auto d-block" />
                                    <img v-else id="expandedImg" src="@/assets/mawj/Logo.png" alt
                                      class="img-fluid mx-auto d-block" />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div style="position:absolute;top:-44px;right:-13px;z-index: 20;">

                                  <div class="d-flex">
                                    <b-dropdown right variant="black" toggle-class="header-item"
                                      class="d-inline-block user-dropdown">
                                      <template v-slot:button-content>
                                        <i class="ri-edit-line"></i>
                                      </template>

                                      <div class="text-center">
                                        <b-button class="align-center" type="button" @click="editCompany(companyInfo)"
                                          pill variant="success">
                                          Replace Image
                                        </b-button>
                                      </div>
                                      <div class="text-center" v-if="companyInfo.cover_image">
                                        <hr>
                                        <b-button class="align-center" type="button" @click="removePic()" pill
                                          variant="danger">
                                          Delete
                                        </b-button>
                                      </div>
                                    </b-dropdown>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end product img -->
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-2 mt-3">

                        <a :href="companyInfo.trade_license" target="_blank">
                          <strong>
                            Trade License
                          </strong>
                          <br>
                          <img v-if="companyInfo.trade_license" :src="companyInfo.trade_license" alt="Trade License"
                            height="180px">
                          <div v-else>
                            No record found
                          </div>
                        </a>
                      </div>
                      <div class="col-2 mt-3">
                        <a :href="companyInfo.emirates_id" target="_blank">
                          <strong>
                            National ID Front
                          </strong>
                          <br>
                          <img v-if="companyInfo.emirates_id" :src="companyInfo.emirates_id" alt="National ID"
                            height="180px">
                          <div v-else>
                            No record found
                          </div>
                        </a>
                      </div>
                      <div class="col-2 mt-3">
                        <a :href="companyInfo.emirates_id_back" target="_blank">
                          <strong>
                            National ID Back
                          </strong>
                          <br>
                          <img v-if="companyInfo.emirates_id_back" :src="companyInfo.emirates_id_back" alt="National ID"
                            height="180px">
                          <div v-else>
                            No record found
                          </div>
                        </a>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Company Admins">
                    <div class="row d-block">
                      <div style="text-align: end;" class="text-right mb-4">
                        <b-button style="cursor: pointer" @click="showMemberActionModal(companyInfo)" pill
                          variant="primary">
                          Add Member
                        </b-button>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <a v-for="member in companyInfo.company_members" :key="member._id"
                          @click="showMemberView(member)" style="cursor: pointer">
                          <div class="product-color-item">
                            <img src="@/assets/mawj/logo.svg" alt class="avatar-lg m-4" />
                          </div>
                          <p>{{ member.name }}</p>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="companyInfo.company_members.length === 0">
                      No Members
                    </div>
                  </b-tab>
                  <b-tab title="Certificate">
                    <div style="position: relative">
                      <companyCertificateModal @added="certificateAdded" :selectedCertificate="selectedCertificate"
                        v-on:resetModal="loadInfo" />
                      <div class="row">
                        <div class="col-md-3" v-for="certificate in companyInfo.company_certificates"
                          :key="certificate._id">
                          <div class="certificate-card">
                            <a :href="certificate.certificate_image" target="_blank">
                              <!-- <img :src="certificate.certificate_image" alt="certificate image" srcset=""
                                style="height: 84px" class="d-block m-auto" /> -->
                              <img v-if="certificate.certificate_image" :src="certificate.certificate_image"
                                alt="certificate image" srcset="" style="height: 84px" class="d-block m-auto" />
                              <img v-else src="@/assets/mawj/Logo.png" style="height: 84px" class="d-block m-auto" />
                            </a>
                            <div class="float-right">
                              <b-dropdown right variant="black" toggle-class="header-item"
                                class="d-inline-block user-dropdown">
                                <template v-slot:button-content>
                                  <i class="ri-menu-line"></i>
                                </template>

                                <div class="text-center">
                                  <b-button @click="showAddCertificate(certificate)" pill variant="primary">
                                    Edit
                                  </b-button>
                                </div>
                                <div class="text-center">
                                  <hr>
                                  <b-button @click="deleteCertificate(certificate)" pill variant="danger">
                                    delete
                                  </b-button>
                                </div>
                              </b-dropdown>
                            </div>

                            <div class="mt-4">
                              <strong>Title :</strong> {{ certificate.title }}
                              <br />
                              <strong>Description:</strong>
                              {{ certificate.description }}
                              <br />
                              <strong>Document:</strong>
                              <a :href="certificate.certificate_document" target="_blank" class="px-2">Click here</a>

                              <br />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Award">

                    <div style="position: relative">
                      <div>
                        <CompanyAwardAdd :companyId="companyInfo._id" v-on:resetModal="loadInfo"
                          :selectedAward="selectedAward"></CompanyAwardAdd>
                      </div>
                      <div class="row">
                        <div class="col-3  " v-for="award, index in companyAwards" :key="index">
                          <div class="m-2 card card-body border-all">

                            <img class="card " v-if="award.award_document" :src="award.award_document" height="100px"
                              width="100px" alt="Project Image">
                            <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                              alt="Project Image" class="card placeholder-img" />
                            <div class="row">


                              <b-dropdown right variant="black" toggle-class="header-item"
                                class="d-inline-block user-dropdown">
                                <template v-slot:button-content>
                                  <i class="ri-menu-line"></i>
                                </template>

                                <div class="text-center">
                                  <b-button @click="showAddAward(award)" pill variant="primary">
                                    Edit
                                  </b-button>
                                </div>
                                <div class="text-center">
                                  <hr>
                                  <b-button @click="deleteAward(award)" pill variant="danger">
                                    Delete
                                  </b-button>
                                </div>
                                <div class="text-center">
                                  <hr>
                                  <a v-if="award.award_document" :href="award.award_document" target="_blank">

                                    Download <i class="dripicons-cloud-download"></i>
                                  </a>

                                </div>
                              </b-dropdown>
                            </div>
                            <h4 class="card-title">{{ award.name }}</h4>
                            <p class="card-title-desc">{{ award.description || '-' }}</p>
                            <p class="card-title-desc" v-if="award.date_received">{{
                            moment(award.date_received).format("LL")
                            }}</p>
                            <p class="card-title-desc " v-else>-</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Projects">
                    <div style="position: relative">
                      <div>
                        <div class="row">
                          <div class="col-3 " v-for="projectInfo, index in  companyProjects" :key="index">
                            <div class="rowClass card-body m-2 card border-all"
                              @click="openProjectPage(projectInfo.project_id)">
                              <img class="card" v-if="projectInfo.cover_image" :src="projectInfo.cover_image"
                                height="100px" width="100px" alt="Project Image">
                              <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                                alt="Project Image" class="card placeholder-img" />
                              <h4 class="card-title colorful" v-if="projectInfo.project_type">{{
                              projectInfo.project_type.name
                              }}
                              </h4>
                              <h4 class="card-title" v-else>-</h4>
                              <h4 class="card-title">{{ projectInfo.name_en }}</h4>
                              <h4 class="card-title">{{ projectInfo.name_ar.length > 13 ?
                              projectInfo.name_ar.substring(0, 12) + ".." : projectInfo.name_ar
                              
                              
                              }}</h4>
                              <p class="card-title-desc  small-font">{{ projectInfo.location_en || '-' }}</p>
                            </div>
                            <!-- {{ projectInfo.cover_image }} -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Project Members">

                    <div style="position: relative">
                      <div>
                        <div class="row" v-for="projectInfo, index in  companyProjects" :key="index">
                          <div class="col-3">
                            <div class=" card-body m-2 card border-all"
                              @click="openProjectPage(projectInfo.project_id)">
                              <img class="card" v-if="projectInfo.cover_image" :src="projectInfo.cover_image"
                                height="100px" width="100px" alt="Project Image">
                              <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                                alt="Project Image" class="card placeholder-img" />
                              <h4 class="card-title colorful" v-if="projectInfo.project_type">{{
                              projectInfo.project_type.name
                              }}
                              </h4>
                              <h4 class="card-title" v-else>-</h4>
                              <h4 class="card-title">{{ projectInfo.name_en }}</h4>
                              <h4 class="card-title">{{ projectInfo.name_ar }}</h4>
                              <p class="card-title-desc">{{ projectInfo.location_en || '-' }}</p>
                            </div>
                          </div>
                          <div class="col-9 row">
                            <div class="col-2"
                              v-for="member, ind in (projectInfo.team_members).filter((m) => m.worked_under == companyInfo._id)"
                              :key="ind">
                              <img class="image-rounded" v-if="member.user && member.user.cover_image"
                                :src="member.user.cover_image" height="100px" width="100px" alt="User Image">
                              <img class="image-rounded" v-else-if="member.picture" :src="member.picture" height="100px"
                                width="100px" alt="User Image">
                              <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                                alt="User Image" class="image-rounded placeholder-img" />
                              <h4 class="card-title"
                                v-if="(member.user && (member.user.name || member.user.first_name))">
                                {{ member.user.name || member.user.first_name }}
                              </h4>
                              <h4 class="card-title" v-else-if="(member.name)">
                                {{ member.name }}
                              </h4>
                              <div v-else>
                                {{ member }}
                              </div>
                            </div>

                          </div>
                          <div class="col-12 border-top my-3">

                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Claims">

                    <div style="position: relative">
                      <div> </div>
                      <div>
                        <div class="row" v-for="claims, index in companyClaims" :key="index">

                          <div class="col-3">
                            <div class=" card-body m-2 card border-all">
                              <img class="card" v-if="claims.project.cover_image" :src="claims.project.cover_image"
                                height="100px" width="100px" alt="Project Image">
                              <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                                alt="Project Image" class="card placeholder-img" />
                              <h4 class="card-title colorful" v-if="claims.project.sub_project_type">{{
                              claims.project.sub_project_type.name
                              }}
                              </h4>
                              <h4 class="card-title" v-else>-</h4>
                              <h4 class="card-title">{{ claims.project.name_en }}</h4>
                              <h4 class="card-title">{{ claims.project.name_ar }}</h4>
                            </div>
                          </div>

                          <div class="col-4 row">
                            <div class="member-container "
                              v-for="member, ind in (claims.team_members).filter((m) => m.worked_under == companyInfo._id)"
                              :key="ind">
                              <img class="image-rounded" v-if="member.user && member.user.cover_image"
                                :src="member.user.cover_image" height="100px" width="100px" alt="User Image">
                              <img class="image-rounded" v-else-if="member.picture" :src="member.picture" height="100px"
                                width="100px" alt="User Image">
                              <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px"
                                alt="User Image" class="image-rounded placeholder-img" />
                              <div>
                                <h4 class=" card-title"
                                  v-if="(member.user && (member.user.name || member.user.first_name))">
                                  {{ member.user.name || member.user.first_name }}
                                </h4>
                                <h4 class="card-title" v-else-if="(member.name)">
                                  {{ member.name }}
                                </h4>
                                <div v-else>
                                  {{ member }}
                                </div>
                                <h4 class="card-title">{{ member.role }} </h4>
                                <h4 class="certificate-card">{{ member.email }} </h4>
                              </div>
                            </div>
                            <div class="col-9" style="padding-left:35px;">
                              <b-button id="approveClaim" @click="editClaim(claims)" pill variant="outline-secondary"
                                style="min-width: 125px; margin-right: 10px; ">
                                Approve
                                Claim
                              </b-button>
                              <b-button id="declineClaim" @click="deleteClaim(claims)" pill variant="outline-secondary"
                                style="min-width: 125px;">
                                Decline
                                Claim
                              </b-button>
                            </div>
                          </div>
                          <div class="col-5" style="  align-self: center;">
                            <h4 class="card-title">{{ "Supporting Documents" }} </h4>
                            <div class="gridStyle">
                              <div v-for="doc, ind in (claims.team_members[0].support_documents)" :key="ind">
                                <a :href="doc" target="_blank">
                                  <img class="image-rounded" v-if="doc" :src="doc" height="100px" width="100px"
                                    alt="Supporting Document">
                                </a>

                              </div>
                            </div>
                          </div>
                          <div class="col-12 border-top my-3">
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
    <CompanyModal :selectCategoryOptions="selectCategoryOptions" :selectedCompany="selectedCompany"
      v-on:resetModal="selectedCompany = null" v-on:reloadData="loadInfo" v-on:closeModal="hideActionModal" />
    <CompanyStatusModal :selectedCompany="selectedCompany" :companyStatuses="companyStatuses"
      v-on:resetModal="selectedCompany = null" v-on:reloadData="loadInfo" v-on:closeModal="hideActionModal" />
    <MemberModal :currentCompany="selectedCompany" :selectedMember="selectedMember"
      v-on:resetModal="selectedCompany = null" v-on:loadInfo="loadInfo" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style scoped>
.small-font {
  font-size: 12px;
}

.image-rounded {
  border-radius: 10px;
}

.colorful {
  color: #576e85;
}

.border-all {
  border: 1px solid black;
}

.placeholder-img {
  background-color: #576e85;
  padding: 10px;
}

.verified_icon {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.expertise-container {
  display: flex;
  flex-wrap: wrap;
}

.member-container {
  display: inline-flex;
  align-items: center;
  row-gap: 20px;
  column-gap: 25px;
  margin: 30px;
}

.expertise-card {
  background-color: #505d69;
  margin: 4px;
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  min-width: 184px;
}

.expertise-card strong {
  text-transform: capitalize;
  font-weight: 900;
  font-size: 14px;
}

.certificate-card {
  background-color: #f1f5f7;
  color: #000;
  padding: 8px;
  border-radius: 12px;

  font-size: 13px;
}

.certificate-card strong {
  font-size: 16px;
}

.gridStyle {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 110px));
  gap: auto;
}
</style>
