<script>
import { cities } from "@/config/api/cities";
import CityModal from "@/components/cities/city-modal";

export default {
  components: { CityModal },
  props: ["currentCountry", "countriesData"],
  data() {
    return {
      cityData: [],
      title: "Cities",
      selectedCity: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "city", sortable: true },
        { key: "createdAt", sortable: true },
        { key: "updatedAt", sortable: true },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    countries() {
      let countries = [];
      this.countriesData.forEach((element) => {
        countries.push({
          _id: element._id,
          name: element.name,
        });
      });
      return countries;
    },
    rows() {
      return this.cityData.length;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.cityData.length;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isBusy = true;
      const api = cities.cityByCountry;

      api.id = this.currentCountry._id;

      await this.generateAPI(api)
        .then((res) => {
          this.cityData = res.data.cities;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editCity(data) {
      this.selectedCity = data;
      this.showActionModal();
    },
    deleteCity(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = cities.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("City deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("City is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showActionModal() {
      this.$bvModal.show("action-City_" + this.currentCountry._id);
    },
    hideActionModal() {
      this.$bvModal.hide("action-City_" + this.currentCountry._id);
    },
  },
};
</script>

<template>
  <div class="row">
    <!-- Table -->
    <div class="card-body pt-0">
      <b-button id="addCities" @click="showActionModal" pill variant="primary"
        >Add Cities</b-button
      >
      <!-- All Cities -->
      <b-tabs
        nav-class="nav-tabs-custom"
        v-model="activeTab"
        active-nav-item-class="font-weight-bold"
        active-tab-class="font-weight-bold"
        content-class="mt-3"
      >
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">All Cities</a>
          </template>
          <div class="table-responsive">
            <b-table
              bordered
              striped
              :items="cityData"
              :fields="fields"
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              :per-page="perPage"
              :current-page="currentPage"
              show-empty
            >
              <template #table-busy>
                <div style="text-align: center" class="mt-5 mb-5">
                  <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                </div>
              </template>
              <template #cell(actions)="row">
                <button class="action-btn" @click="deleteCity(row.item)">
                  <i class="ri-delete-bin-line"></i>
                </button>
                <button class="action-btn" @click="editCity(row.item)">
                  <i class="ri-edit-line"></i>
                </button>
              </template>
              <template #cell(city)="row">
                {{ row.item.name }}
              </template>
              <template #cell(country)>
                {{ currentCountry.name }}
              </template>
              <template #cell(createdAt)="row">
                {{ moment(row.item.createdAt).format("l") }}
              </template>
              <template #cell(updatedAt)="row">
                {{ moment(row.item.updatedAt).format("l") }}
              </template>
              <template #cell(deletedAt)="row">
                {{
                  !row.item.deletedAt
                    ? ""
                    : moment(row.item.deletedAt).format("l")
                }}
              </template>
              <template #cell(deletedBy)="row">
                {{ !row.item.deletedBy ? "" : row.item.deletedBy.name }}
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>
      <CityModal
        :currentCountry="currentCountry"
        :countries="countries"
        :selectedCity="selectedCity"
        v-on:resetModal="selectedCity = null"
        v-on:reloadData="loadData"
        v-on:closeModal="hideActionModal"
      />
    </div>
  </div>
</template>

<style scoped>
#addCities {
  float: right;
  margin-top: 10px;
}
.row {
  background-color: #f1f5f7;
}
</style>